import React from "react";

export default function UpperWorkSplash() {
  return (
    <div className="upper-work-splash" data-aos='fade-down'>
      <svg
        viewBox="0 0 1385 560"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1376.85 23.9087C1302.12 52.0287 1238.78 109.289 1203.3 180.819C1185.14 217.419 1170.62 261.209 1133.46 278.199C1103.88 291.729 1075.13 280.619 1044.1 280.569C1015.71 280.519 986.421 286.099 964.611 305.519C955.871 313.299 948.891 322.829 942.441 332.589C908.951 383.309 888.261 442.109 850.811 489.979C813.361 537.849 750.151 574.169 692.681 554.399C652.611 540.609 625.681 503.549 603.761 467.299C581.841 431.049 560.761 391.949 525.271 368.789C442.641 314.879 359.631 373.789 310.001 440.789C297.001 458.409 285.121 477.359 268.221 491.329C252.451 504.329 233.001 512.329 213.101 517.109C191.451 522.289 168.661 523.799 146.941 518.989C111.161 511.069 80.9409 486.579 58.1809 457.839C7.25088 393.459 -10.3891 305.519 5.84088 225.049C17.9509 165.049 51.3409 98.8888 92.4409 52.7788C133.541 6.66877 214.441 18.0988 269.581 15.3388C576.751 -0.0212336 886.361 -15.2613 1190.49 30.5087C1254.88 40.1987 1321.57 52.5088 1384.15 34.5088"
          fill="#FC7E29"
        />
      </svg>
    </div>
  );
}
