import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "../button/Button";
import { getTotalPrice } from "../../utils/getTotalPrice";
import { ProductType } from "../../models/Product";

interface Props {
  allowed: boolean;
  cart: ProductType[];
}

const CheckoutSummary = ({ allowed, cart }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="checkout-summary">
      <h3 className="checkout-summary__header">Your Purchase Summary</h3>
      <div className="checkout-summary__info">
        <span className="checkout-summary__info-title">Sub Total:</span>
        <h2 className="total-price">{`${getTotalPrice(cart)} SAR`}</h2>
      </div>

      <Button
        text={allowed ? "DOWNLOAD THE ITEMS" : "Complete Purchase"}
        onClick={() => navigate(allowed ? "/downloads" : "/paypal")}
        type="button"
        lightPink
      />
    </div>
  );
};

export default CheckoutSummary;
