import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { Carousel } from "antd";

import BlogTeaser from "../blogs/BlogTeaser";
import Button from "../button/Button";
import FeedBackMessage from "./FeedBackMessage";
import HeaderHomeLogo from "../elements/HeaderHomeLogo";
import Navbar from "../navbar/Navbar";
import ShopHeader from "../elements/ShopHeader";

import { BlogType } from "../../models/Blog";
import { getStoryblokData } from "../../utils/getStoryblokData";
import BottomWorkSplash from "../../assets/splashes/BottomWorkSplash";
import LandWhiteSplash from "../../assets/splashes/LandWhiteSplash";
import LeftSplash from "../../assets/splashes/LeftSplash";
import RightSplash from "../../assets/splashes/RightSplash";
import UpperWorkSplash from "../../assets/splashes/UpperWorkSplash";
import UpperWorkSplash2 from "../../assets/splashes/UpperWorkSplash2";
import logo_lands from "../../assets/svgs/logo.svg";

interface Props {
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const Home = ({ openMenu, setOpenMenu }: Props) => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [homeBlogs, setHomeBlogs] =
    useState<{ slug: string; content: BlogType }[]>();
  const [animateShop, setAnimateShop] = useState(false);

  const ShopHeaderRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const setFixedNavbar = () => {
    if (window.scrollY >= 560) {
      setIsNavbarFixed(true);
    } else {
      setIsNavbarFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", setFixedNavbar);
    window.addEventListener("scroll", handleScrollToHeader);

    const getBlogs = async () => {
      const { data: blogData } = await getStoryblokData<{
        stories: { slug: string; content: BlogType }[];
      }>("cdn/stories/", "blog");
      setHomeBlogs(blogData?.stories);
    };
    getBlogs();
    return () => {
      window.removeEventListener("scroll", handleScrollToHeader);
    };
  }, []);

  const handleScrollToHeader = () => {
    const currentHeader = ShopHeaderRef.current;
    if (!currentHeader) return;

    if (
      window.scrollY >=
      ShopHeaderRef.current?.offsetTop - ShopHeaderRef.current?.offsetHeight
    ) {
      setAnimateShop(true);
    } else {
      setAnimateShop(false);
    }
  };

  return (
    <main className="home-page">
      {isNavbarFixed ? <div style={{ height: "15vh" }}></div> : null}
      <section className="home-page__land">
        <LandWhiteSplash />
        <div className="home-page__land-logo" data-aos="zoom-in-up">
          <HeaderHomeLogo />
        </div>

        <img
          className="home-page__spinner-logo-1"
          src={String(logo_lands)}
          alt="logo art"
        />
        <img
          className="home-page__spinner-logo-2"
          src={String(logo_lands)}
          alt="logo art"
        />
        <img
          className="home-page__spinner-logo-3"
          src={String(logo_lands)}
          alt="logo art"
        />
      </section>
      {
        <div
          className={classNames("", {
            "home-page__navbar--fixed": isNavbarFixed,
          })}
        >
          <Navbar onOpenMenu={() => setOpenMenu(!openMenu)} />
        </div>
      }

      <section className="home-page__work-section">
        <UpperWorkSplash />
        <UpperWorkSplash2 />
        <div className="home-page__work-content">
          <h1 className="home-page__work__my-word">My</h1>
          <h1 className="home-page__work__work-word">Work</h1>
          <Button
            type="button"
            orange
            onClick={() => navigate("/gallery")}
            text="check it out"
          />
        </div>
        <BottomWorkSplash />
      </section>

      <section className="home-page__quote-section">
        <RightSplash />
        <LeftSplash />
        <h1 className="home-page__quote-text">
          GREAT THINGS ARE COMMING SOON!!
        </h1>
      </section>

      <section className="home-page__shop-section">
        <div
          ref={ShopHeaderRef}
          className={classNames("home-page__shop-header", {
            "home-page__shop-header--animate": animateShop,
          })}
        >
          <svg
            viewBox="0 0 742 271"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.27002 223.38C0.27002 201.58 16.35 180.21 39.7 180.21C55.11 180.21 63.48 192.77 63.48 205.1C63.48 216.1 56.66 227.1 42.56 228.67C41.4618 226.479 39.7573 224.65 37.6495 223.4C35.5416 222.15 33.119 221.533 30.67 221.62C24.94 221.62 21.85 226.25 21.85 232.19C21.85 248.93 38.15 259.29 55.11 259.29C73.17 259.29 91.9 247.39 91.9 218.76C91.9 180.65 12.6 183.08 12.6 123.61C12.6 87.49 50.27 68.76 85.73 68.1C108.42 27.8 170.31 0.710083 214.36 0.710083C241.23 0.710083 261.49 10.84 261.49 34.63C261.49 46.74 252.9 57.3099 235.72 57.3099C235.72 46.0799 215.46 39.91 189.91 39.91C159.07 39.91 120.53 48.9101 100.91 68.9901C132.4 72.9901 144.96 91.45 144.96 108.85C144.96 130.22 129.54 141.45 113.46 141.45C95.62 141.45 77.12 128.01 77.12 99.6C77.1851 93.3367 78.1551 87.1158 80 81.13C67.89 84.87 57.54 93.6799 57.54 107.78C57.54 145 136.61 141.48 136.61 206.67C136.61 248.74 97.84 270.77 61.5 270.77C29.78 270.74 0.27002 254 0.27002 223.38ZM104.67 118.13C113.26 118.13 121.85 111.52 121.85 99.41C121.85 84.21 105.55 79.15 93 79.15C89.7981 85.0273 88.13 91.6171 88.15 98.3099C88.15 111.71 96.3 118.13 104.67 118.13Z"
              fill="#E22758"
            />
            <path
              d="M166.35 80.2201V70.3099H247.84V80.2201C242.55 80.2201 232.42 85.28 232.42 92.33V156.87H285.28V92.33C285.28 85.33 275.15 80.2201 269.87 80.2201V70.3099H351.36V80.2201C346.07 80.2201 335.94 85.28 335.94 92.33V233.33C335.94 240.33 346.07 245.44 351.36 245.44V255.35H269.87V245.44C275.15 245.44 285.28 240.37 285.28 233.33V167.91H232.42V233.33C232.42 240.33 242.55 245.44 247.84 245.44V255.35H166.35V245.44C171.63 245.44 181.76 240.37 181.76 233.33V92.33C181.76 85.28 171.63 80.2201 166.35 80.2201Z"
              fill="#E22758"
            />
            <path
              d="M392.33 162.37C392.33 106.21 413.26 68.1 471.62 68.1C529.98 68.1 550 106.21 550 162.37C550 218.76 530.84 257.52 471.81 257.52C412.78 257.52 392.33 218.76 392.33 162.37ZM471.18 246.51C497.83 246.51 497.18 190.34 497.18 163.25C497.18 136.6 498.73 79.12 471.63 79.12C444.53 79.12 445.2 136.6 445.2 163.25C445.19 190.34 444.53 246.51 471.18 246.51Z"
              fill="#E22758"
            />
            <path
              d="M595.41 233.3V92.2999C595.41 85.2999 585.28 80.1901 579.99 80.1901V70.2799H666.33C704.22 70.2799 741.88 76.88 741.88 123.14C741.88 174.02 694.3 175.78 654.66 175.78H646.07V233.27C646.07 240.27 656.2 245.38 661.49 245.38V255.29H580V245.38C585.28 245.41 595.41 240.34 595.41 233.3ZM646.07 164.8C678.23 164.8 691.22 152.24 691.22 119.64C691.22 103.13 685.93 80.2201 665.67 80.2201C656.67 80.2201 646.07 82.64 646.07 92.33V164.8Z"
              fill="#E22758"
            />
          </svg>
          <Button
            onClick={() => navigate("/shop")}
            text="Shop now"
            type="button"
            orange
          />
        </div>

        {animateShop && (
          <div className="home-page__shop-icon">
            <ShopHeader />
          </div>
        )}
      </section>

      <section className="home-page__feedback-section">
        <Carousel autoplay>
          <FeedBackMessage
            avatar="female-avatar1"
            username="متجر زوز "
            content="  تصاميمها ماشاءالله لا قوة الا بالله ابداع و ذوق لا تتخطونها
                  لمشاريعكم اة منتجاتكم"
          />
          <FeedBackMessage
            avatar="male-avatar2"
            username="Soon"
            content="    الله يسعدك .. ماشاء الله تبارك الرحمن قمه في الجمال والابداع
            جداً اغرمت بالشعار صراحة 🤩😭❤️ تسلم أناملك ياروحي.🙏🏻❤️"
          />
          <FeedBackMessage
            avatar="female-avatar2"
            username="المهاجر"
            content="  جزيل الشكر والتقدير والعرفان على تصميمك الرائع ماشاء الله عليك
            كفو بارك الله فيك"
          />
        </Carousel>
      </section>

      <section className="home-page__blog-section">
        <h1 className="home-page__blog-header">My Blog</h1>

        <div className="blogs-page__blogs-container">
          {Boolean(homeBlogs?.length) &&
            homeBlogs?.map((blog) => <BlogTeaser blog={blog} />)}
        </div>
      </section>
    </main>
  );
};

export default Home;
