import React from "react";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  onClick: () => void;
  to: string;
  type: "external" | "internal" | string;
}
const MenuAsideItem = ({ label, onClick, to, type }: Props) => {
  return (
    <button type="button" className="menu-aside__item" onClick={onClick}>
      {type === "internal" ? (
        <Link to={to} className="menu-aside__item-link">
          {label}
        </Link>
      ) : (
        <a className="menu-aside__item-link" href={to}>
          {label}
        </a>
      )}
    </button>
  );
};

export default MenuAsideItem;
