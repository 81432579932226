import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  label: string;
  link: string;
}
const NavItem = ({ label, link }: Props) => {
  return (
    <div className="navbar__nav">
      <NavLink
        to={link}
        className={({ isActive }) =>
          classNames("navbar__nav-link", {
            "navbar__nav-link--active": isActive,
          })
        }
      >
        {label}
      </NavLink>
    </div>
  );
};

export default NavItem;
