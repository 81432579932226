import React from "react";
import RichTextEditor from "react-rte";
import Button from "../button/Button";

const AddBlogForm = ({ addBlog, BlogData, setBlogsData, handleBlogImage }) => {
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createValueFromString(BlogData.content, "markdown")
  );

  const handleChange = (value) => {
    setEditorValue(value);
    setBlogsData({ ...BlogData, content: value.toString("markdown") });
  };
  return (
    <div className="admin-panel__blog-form">
      <form onSubmit={addBlog} style={{ width: "80%" }}>
        <h4 className="text-center p-3">Add Blog</h4>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="name">
            Name Of Blog
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            placeholder="some name..."
            value={BlogData.name}
            onChange={(e) =>
              setBlogsData({ ...BlogData, name: e.target.value })
            }
          />
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="content">
            Content
          </label>
          <RichTextEditor
            value={editorValue}
            onChange={handleChange}
            type="string"
            multiline
            variant="filled"
            style={{ minHeight: 410 }}
          />
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="img">
            Blog Image
          </label>
          <input
            className="form-control"
            type="file"
            id="img"
            onChange={handleBlogImage}
          />
        </div>

        <Button text="Add new Blog" pink type="submit" />
      </form>
    </div>
  );
};
export default AddBlogForm;
