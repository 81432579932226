import React from "react";
import Button from "../button/Button";
import Loading from "../loading/Loading";

const AddProductForm = ({
  addProduct,
  pdata,
  setPdata,
  handleFileUpload,
  handleMainImage,
  loading,
  handleSecondImage,
}) => {
  return (
    <div className="admin-panel__product-form">
      <form onSubmit={addProduct} style={{ width: "80%" }}>
        <h4 className="text-center p-3">Add Product</h4>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="name">
            Name Of Product
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            placeholder="some name..."
            value={pdata.name}
            onChange={(e) => setPdata({ ...pdata, name: e.target.value })}
          />
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="file_pdf">
            File Product
          </label>
          <input
            className="form-control"
            type="file"
            id="file_pdf"
            placeholder="PDF File."
            onChange={handleFileUpload}
          />
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="price">
            Price Of Product
          </label>
          <input
            className="form-control"
            type="number"
            id="price"
            placeholder="$100.."
            value={pdata.price}
            onChange={(e) => setPdata({ ...pdata, price: e.target.value })}
          />
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="img">
            Main Image
          </label>
          <input
            className="form-control"
            type="file"
            id="img"
            onChange={handleMainImage}
          />
        </div>

        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="name">
            Category
          </label>

          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="radio1"
              name="cat"
              value="BOOKS"
              required
              onChange={(e) => setPdata({ ...pdata, cate: e.target.value })}
            />
            BOOKS
            <label className="form-check-label" for="radio1"></label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="radio2"
              name="cat"
              value="COURSES"
              required
              onChange={(e) => setPdata({ ...pdata, cate: e.target.value })}
            />
            COURSES
            <label className="form-check-label" for="radio2"></label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="radio2"
              name="cat"
              value="DESIGN SERVICES"
              required
              onChange={(e) => setPdata({ ...pdata, cate: e.target.value })}
            />
            DESIGN SERVICES
            <label className="form-check-label" for="radio2"></label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="radio2"
              name="cat"
              value="DESIGN TEMPS"
              required
              onChange={(e) => setPdata({ ...pdata, cate: e.target.value })}
            />
            DESIGN TEMPS
            <label className="form-check-label" for="radio2"></label>
          </div>
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="discount">
            Discount
          </label>
          <input
            className="form-control"
            id="discount"
            type="number"
            placeholder="10%... put just number like 10"
            onChange={(e) => setPdata({ ...pdata, discount: e.target.value })}
            value={pdata.discount}
          />
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="des">
            Description
          </label>
          <textarea
            rows="5"
            className="form-control"
            id="des"
            placeholder="About Product..."
            onChange={(e) => setPdata({ ...pdata, describe: e.target.value })}
            value={pdata.describe}
          />
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="second_image">
            Second Image 1 x 1
          </label>
          <input
            className="form-control"
            id="second_image"
            type="file"
            onChange={handleSecondImage}
          />
        </div>

        <Button text="Add New Product" type="submit" pink disabled={loading} />
        {loading && <Loading />}
      </form>
    </div>
  );
};
export default AddProductForm;
