import React from "react";
import { storyblokInit, apiPlugin } from "@storyblok/react";

export const components = {};

storyblokInit({
  accessToken: process.env.REACT_APP_EXAMPLE,
  use: [apiPlugin],
  components,

  apiOptions: {
    // for spaces located in the US or China:
    // region: "us" or "cn", // you need to specify the region
    region: "eu",
  },
});
export default function StoryblokProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return children;
}
