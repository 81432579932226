import React from "react";

export default function Polygon() {
  return (
    <div className="comment-polygon">
      <svg
        viewBox="0 0 514 581"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.414228 47.4149C0.126847 11.768 38.7438 -10.6445 69.553 7.28823L490.853 252.509C521.355 270.263 521.327 314.334 490.803 332.05L73.4328 574.283C42.9087 591.999 4.62853 570.16 4.34401 534.869L0.414228 47.4149Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
