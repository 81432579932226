import React from "react";
import Button from "../button/Button";
export default function NotFound() {
  return (
    <div className=" port-page">
      <h1><b>404</b></h1>
      <h1 className="text-center m-5">PAGE NOT FOUND</h1>
      <Button
        pink
        text="Go Back home"
        onClick={() => (window.location.href = "/")}
      />
    </div>
  );
}
