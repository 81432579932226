import React from "react";

import tiktok from "../../../assets/svgs/tiktok-icon.svg";
import x from "../../../assets/svgs/X.svg";
import youtube from "../../../assets/svgs/youtube-icon.svg";
import instagram from "../../../assets/svgs/insta-icon.svg";

const AboutGeneral = () => {
  return (
    <div className="about-general">
      <div className="about-general__section">
        <span className="about-general__header">contact</span>
        <span className="about-general__item">
          <i className="bx bxs-envelope"></i> x.mxdbs@gmail.com
        </span>
        <span className="about-general__item">
          <i className="bx bx-globe"></i> mahabnsahl.com
        </span>
      </div>
      <div className="about-general__section">
        <span className="about-general__header">follow</span>
        <span className="about-general__social">
          <img
            className="about-general__icon"
            src={String(tiktok)}
            alt="icon"
          />
          https://www.tiktok.com/@maha_binsahl
        </span>
        <span className="about-general__social">
          <img
            className="about-general__icon"
            src={String(youtube)}
            alt="icon"
          />
          https://www.youtube.com/@MahaBinSahl
        </span>
        <span className="about-general__social">
          <img className="about-general__icon" src={String(x)} alt="icon" />
          https://twitter.com/Maha_BinSahl
        </span>
        <span className="about-general__social">
          <img
            className="about-general__icon"
            src={String(instagram)}
            alt="icon"
          />
          https://www.instagram.com/maha_binsahl/
        </span>
      </div>
      <div className="about-general__section">
        <span className="about-general__header">languages</span>
        <span className="about-general__social">
          <span className="about-general__item">ARABIC</span>
          <div className="about-section__skill-progress">
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
          </div>
        </span>
        <span className="about-general__social">
          <span className="about-general__item">ENGLISH</span>
          <div className="about-section__skill-progress">
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value" />
          </div>
        </span>
        <span className="about-general__social">
          <span className="about-general__item">TURKEY</span>
          <div className="about-section__skill-progress">
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value skill-value" />
            <s className="about-section__value " />
            <s className="about-section__value " />
          </div>
        </span>
        <span className="about-general__social">
          <span className="about-general__item">SPANISH</span>
          <div className="about-section__skill-progress">
            <s className="about-section__value skill-value" />
            <s className="about-section__value" />
            <s className="about-section__value" />
            <s className="about-section__value" />
            <s className="about-section__value" />
          </div>
        </span>
      </div>
      <div className="about-general__section">
        <span className="about-general__header">HOBBIES</span>
        <span className="about-general__items">
          <span className="about-general__hobby">Digital Arts</span>
          <span className="about-general__hobby">Podcasting</span>
          <span className="about-general__hobby">Gaming</span>
          <span className="about-general__hobby">Writing</span>
          <span className="about-general__hobby">Music</span>
        </span>
      </div>
    </div>
  );
};

export default AboutGeneral;
