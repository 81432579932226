import { ProductType } from "../models/Product";

export const getTotalPrice = (products: ProductType[]) => {
  return products.reduce(
    (total, item) =>
      item.discount !== 0
        ? total + (item.price - (item.price * item.discount) / 100)
        : total + item.price,
    0
  ).toFixed(2);
};
