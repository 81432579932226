import classNames from "classnames";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  theme?: 'brand' | 'white';
}
const Modal = ({ isOpen, onClose, children, theme = 'brand' }: Props) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div className={classNames("modal-body", {
        "modal-body--white": theme === 'white',
      })} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
