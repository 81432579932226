import React from "react";
import { Link } from "react-router-dom";
import work_logo from "../../assets/svgs/logo-white-stroke.svg";

export default function ProductItem({ product }) {
  return (
    <div className="work-card" data-aos="zoom-in-up">
      <Link to={`/product/${product.id}`} className="empty-link">
        <img
          className="work-img"
          src={product.main_image}
          alt={product.title}
        />
        <img className="logo-card" src={work_logo} alt={product.title} />
        <h4 className="work-title mc_5">{product.title}</h4>
        <p className="work-price mx-number">
          <span>{product.price}</span> SAR
        </p>
      </Link>
    </div>
  );
}
