import React, { useContext } from "react";
import { UserContext } from "../../App";
import Button from "../button/Button";
import { ProductType } from "../../models/Product";

const Downloads = () => {
  const { user } = useContext(UserContext);

  const downloadFile = (item: ProductType) => {
    const link = document.createElement("a");
    link.setAttribute("href", item.file);
    link.download = item.title;
    link.click();
  };

  return (
    <div className="downloads">
      <h1 className="downloads__header">Download your Files</h1>
      <div className="downloads__container">
        {user.cart.map((item) => (
          <div className="downloads__card" key={item.id}>
            <img
              src={item.main_image}
              alt={`${item.title}`}
              className="downloads__image"
            />
            <Button
              text="Download Now!"
              pink
              type="button"
              onClick={() => downloadFile(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Downloads;
