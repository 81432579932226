import React from "react";
import { Link } from "react-router-dom";

import { ProductType } from "../../models/Product";

interface Props {
  product: ProductType;
  slug?: string
}
const ProductItem = ({ product, slug }: Props) => {
  return (
    <Link
      className="product-item"
      to={`/product/${slug}`}
      data-aos="zoom-in-up"
    >
      <img
        className="product-item__image"
        src={product.main_image}
        alt={product.title}
      />
      <h4 className="product-item__title">{product.title}</h4>
      <p className="product-item__price">
        <span>{product.price}</span> SAR
      </p>
    </Link>
  );
};

export default ProductItem;
