import React from "react";
import Polygon from "../../assets/splashes/Polygon";
import { getAvatar } from "../../utils/getAvatar";

interface Props {
  username: string;
  avatar: string;
  content: string;
}

const FeedBackMessage = ({ avatar, content, username }: Props) => {
  return (
    <div className="home-page__feedback-slider">
      <div className="home-page__feedback">
        <img
          className="home-page__feedback-avatar"
          src={String(getAvatar(avatar))}
          alt="avatar"
        />
        <div className="home-page__feedback-body">
          <div className="home-page__feedback-activity-bar">
            <span className="home-page__feedback-stars">
              <i className="bx bxs-star"></i>
              <i className="bx bxs-star"></i>
              <i className="bx bxs-star"></i>
              <i className="bx bxs-star"></i>
              <i className="bx bxs-star"></i>
            </span>
            <i className="bx bxs-heart"></i>
          </div>
          <div className="home-page__feedback-info">
            <p className="home-page__feedback-username">{username}</p>
            <p className="home-page__feedback-content">{content}</p>
          </div>
        </div>
        <Polygon />
      </div>
    </div>
  );
};

export default FeedBackMessage;
