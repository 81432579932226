import classNames from "classnames";
import React, { useState } from "react";

interface Props {
  errorMessage?: boolean;
  isRequired?: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: "taxt" | "email" | "password" | "tel" | "url" | "number";
  value: string | undefined;
  variant?: "purple" | "pink" | "orange";
  ref?: React.RefObject<HTMLInputElement>;
}
const Input = ({
  errorMessage,
  isRequired,
  name,
  onChange,
  placeholder,
  type,
  value,
  ref,
  variant,
}: Props) => {
  const [error, setError] = useState(false);
  return (
    <input
      className={classNames("mx-input", {
        "mx-input--pink": variant === "pink",
        "mx-input--orange": variant === "orange",
        "mx-input--purple": variant === "purple",
        "mx-input--error": error,
      })}
      value={value}
      required={isRequired}
      name={name}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      onInvalid={() => setError(true)}
      ref={ref}
    />
  );
};

export default Input;
