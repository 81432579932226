import React, { FormEvent } from "react";
import Modal from "../modal/Modal";
import Input from "../form/input/Input";

import maleAvatar1 from "../../assets/svgs/male-avatar1.svg";
import maleAvatar2 from "../../assets/svgs/male-avatar2.svg";
import femaleAvatar1 from "../../assets/svgs/female-avatar1.svg";
import femaleAvatar2 from "../../assets/svgs/female-avatar2.svg";
import classNames from "classnames";
import Button from "../button/Button";

export type EditFormType = {
  username: string;
  email: string;
  avatar: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  editForm: EditFormType;
  setEditForm: (data: EditFormType) => void;
  updateProfile: (e: FormEvent) => void;
}

const UpdateProfileModal = ({
  isOpen,
  onClose,
  editForm,
  setEditForm,
  updateProfile,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h1 className="update-profile-modal__header">Update my information</h1>
      <form className="update-profile-modal__form" onSubmit={updateProfile}>
        <div className="update-profile-modal__options">
          <span
            onClick={() =>
              setEditForm({ ...editForm, avatar: "male-avatar1" })
            }
            className={classNames("update-profile-modal__avatar-option", {
              "update-profile-modal__avatar-option--checked":
                editForm.avatar === "male-avatar1",
            })}
          >
            <img
              className="update-profile-modal__avatar"
              src={String(maleAvatar1)}
              alt="avatar option"
            />
          </span>
          <span
            onClick={() =>
              setEditForm({ ...editForm, avatar: "male-avatar2" })
            }
            className={classNames("update-profile-modal__avatar-option", {
              "update-profile-modal__avatar-option--checked":
                editForm.avatar === "male-avatar2",
            })}
          >
            <img
              className="update-profile-modal__avatar"
              src={String(maleAvatar2)}
              alt="avatar option"
            />
          </span>
          <span
            onClick={() =>
              setEditForm({ ...editForm, avatar: "female-avatar1" })
            }
            className={classNames("update-profile-modal__avatar-option", {
              "update-profile-modal__avatar-option--checked":
                editForm.avatar === "female-avatar1",
            })}
          >
            <img
              className="update-profile-modal__avatar"
              src={String(femaleAvatar1)}
              alt="avatar option"
            />
          </span>
          <span
            onClick={() =>
              setEditForm({ ...editForm, avatar: "female-avatar2" })
            }
            className={classNames("update-profile-modal__avatar-option", {
              "update-profile-modal__avatar-option--checked":
                editForm.avatar === "female-avatar2",
            })}
          >
            <img
              className="update-profile-modal__avatar"
              src={String(femaleAvatar2)}
              alt="avatar option"
            />
          </span>
        </div>
        <Input
          placeholder="Username"
          name="username"
          value={editForm.username}
          onChange={(e) =>
            setEditForm({ ...editForm, username: e.target.value })
          }
        />
        <Input
          placeholder="Email"
          name="email"
          value={editForm.email}
          onChange={(e) => setEditForm({ ...editForm, email: e.target.value })}
        />
        <Button text="Update" type="submit" orange />
      </form>
    </Modal>
  );
};

export default UpdateProfileModal;
