import { useEffect, useState } from "react";
import { getStoryblokData } from "./getStoryblokData";
import { CategoryType } from "../models/Category";

export const useCategories = () => {
  const [categories, setCategories] = useState<
    { slug: string; content: CategoryType }[]
  >([]);

  useEffect(() => {
    const getCategories = async () => {
      const { data: categoriesData } = await getStoryblokData<{
        stories: { slug: string; content: CategoryType }[];
      }>("cdn/stories/", "categories");
      setCategories(categoriesData?.stories!);
    };
    getCategories();
  }, []);

  return categories;
};
