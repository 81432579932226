"use server";

/* eslint-disable camelcase */

import { getStoryblokApi } from "./getStoryblokAPI";

interface GetStoryblokApi<T> {
  isError: boolean;
  error?: string;
  data?: T;
}

export const getStoryblokData = async <T>(
  path: string,
  starts_with?: string
): Promise<GetStoryblokApi<T>> => {
  try {
    const locale = 'en'
    const isDraft = process.env.IS_STORYBLOK_DRAFT === "true";
    const sbParams = starts_with
      ? {
          version: `${isDraft ? "draft" : "published"}` as
            | "draft"
            | "published",
          language: locale,
          starts_with: starts_with || "",
        }
      : {
          version: `${isDraft ? "draft" : "published"}` as
            | "draft"
            | "published",
          language: locale,
        };

    const storyblokApi = getStoryblokApi();
    const { data } = await storyblokApi.get(path, sbParams, {
      cache: "no-store",
    });

    return { data, isError: false };
  } catch (error) {
    return {
      isError: true,
      error:
        typeof error !== "object" ? (error as string) : "something went wrong",
    };
  }
};
