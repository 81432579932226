import React from "react";
import { ProductType } from "../../models/Product";
import { getFinalPrice } from "../../utils/getFinalPrice";
import classNames from "classnames";

interface Props {
  item: ProductType;
  onRemoveItem?: (id: string) => void;
}
const CartItem = ({ item, onRemoveItem }: Props) => {
  return (
    <div className="cart-item">
      <i
        className="bx bxs-trash"
        onClick={() => onRemoveItem && onRemoveItem(item.id)}
      ></i>
      <img className="cart-item__image" src={item.second_image} alt="product" />
      <div className="cart-item__body">
        <h3 className="cart-item__title">{item.title}</h3>
        <p className="cart-item__price">
          <span
            className={classNames("", {
              "cart-item__price--discount": item.discount !== 0,
            })}
          >
            {item.price} SAR
          </span>
          {item.discount !== 0 &&
            getFinalPrice(item.price, item.discount) + " SAR"}
        </p>
      </div>
    </div>
  );
};

export default CartItem;
