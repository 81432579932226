import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import Aos from "aos";
import axios from "axios";

import "./css/bootstrap.min.css";

import "./css/mxStyles.css";
import "./css/tablet.css";
import "./css/animation.css";
import "aos/dist/aos.css";

import About from "./components/pages/About/About";
import AdminPanel from "./components/Admin/AdminPanel";
import Blog from "./components/Blog/Blog";
import Blogs from "./components/blogs/Blogs";
import Breath from "./components/breath/Breath";
import Cart from "./components/cart/Cart";
import Category from "./components/pages/Category";
import Checkout from "./components/checkout/Checkout";
import Downloads from "./components/checkout/Downloads";
import Footer from "./components/footer/Footer";
import Gallery from "./components/gallery/Gallery";
import Home from "./components/home/Home";
import MenuAside from "./components/menu-aside/MenuAside";
import Message from "./components/message/Message";
import Navbar from "./components/navbar/Navbar";
import NotFound from "./components/pages/NotFound";
import Product from "./components/product/Product";
import Profile from "./components/profile/Profile";
import Project from "./components/project/Project";
import Shop from "./components/shop/Shop";
import Sign from "./components/sign/Sign";

import { UserType } from "./models/User";

Aos.init();
const initialMessageContext = {
  onError: (_message: any) => {},
  onSuccess: (_message: any) => {},
};

type UserContextType = {
  user: UserType;
  setUser: (user: any) => void;
};

export const apiurl = process.env.REACT_APP_BASE_API_URL;
export const UserContext = createContext<UserContextType>({
  user: {
    id: "string",
    username: "string",
    avatar: "string",
    email: "string",
    cart: [],
  },
  setUser: (_data: any) => {},
});

export const MessageContext = createContext(initialMessageContext);
export const IsAuthContext = createContext(false);

function App() {
  const locate = useLocation();
  const [user, setUser] = useState({
    id: "",
    username: "",
    avatar: "guest",
    email: "",
    cart: [],
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<"success" | "error">(
    "success"
  );

  const onSuccess = (message: string) => {
    setMessage(message);
    setMessageType("success");
  };

  const onError = (message: string) => {
    setMessage(message);
    setMessageType("error");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(apiurl + "/isAuth", {
          token,
        })
        .then((res) => {
          const { data } = res;

          setUser({...data, cart: []});
          setIsAuth(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    // setIsAuth(true);
    setTimeout(() => {
      setMessage("");
    }, 5000);

  }, [isAuth, message]);
  const getRoutes = () => {
    if (
      locate.pathname.toLocaleLowerCase().includes("sign") ||
      locate.pathname.toLocaleLowerCase().includes("register") ||
      locate.pathname.toLocaleLowerCase().includes("admin") ||
      locate.pathname === "/"
    )
      return true;
    else return false;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [locate]);

  return (
    //render the app-----------------------------------
    <IsAuthContext.Provider value={isAuth}>
      <MessageContext.Provider value={{ onError, onSuccess }}>
        <UserContext.Provider value={{ user, setUser }}>
          <Message
            content={message}
            show={Boolean(message)}
            type={messageType}
          />
          <main className="app">
            {!getRoutes() && <Navbar onOpenMenu={() => setOpenMenu(true)} />}

            <div onClick={() => (openMenu ? setOpenMenu(false) : openMenu)}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home openMenu={openMenu} setOpenMenu={setOpenMenu} />
                  }
                />
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/product/:slug" element={<Product />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/about" element={<About />} />
                <Route path="/blog/:slug" element={<Blog />} />
                <Route path="/sign" element={<Sign />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/project/:slug" element={<Project />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/cat/:category" element={<Category />} />
                <Route path="/breath" element={<Breath />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/downloads" element={<Downloads />} />
              </Routes>
            </div>
            <MenuAside
              openMenu={openMenu}
              onCloseMenu={() => setOpenMenu(false)}
            />
            <Footer />
          </main>
        </UserContext.Provider>
      </MessageContext.Provider>
    </IsAuthContext.Provider>
  );
}

export default App;
