import React from "react";

import icon from "./assets/skills-icon.svg";
import logo from "../../../assets/svgs/logo.svg";

const AboutSkillsSection = () => {
  return (
    <section className="about-section">
      <header className="about-section__header">
        <img className="about-section__icon" src={String(icon)} alt="icon" />
        <h2 className="about-section__title">SKILLS</h2>
      </header>

      <div className="about-section__body">
        <div className="about-section__skills">
          <div className="about-section__skill">
            <p className="about-section__label-skill">ILLUSTRATOR</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
            </div>
          </div>
          <div className="about-section__skill">
            <p className="about-section__label-skill">PHOTOSHOP</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-valuE" />
              <s className="about-section__value" />
            </div>
          </div>
          <div className="about-section__skill">
            <p className="about-section__label-skill">IN DESIGN</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value" />
              <s className="about-section__value" />
            </div>
          </div>
          <div className="about-section__skill">
            <p className="about-section__label-skill">AFTER EFFECT</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value" />
              <s className="about-section__value" />
              <s className="about-section__value" />
            </div>
          </div>
          <div className="about-section__skill">
            <p className="about-section__label-skill">COMMINUCATION SKILLS</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
            </div>
          </div>
          <div className="about-section__skill">
            <p className="about-section__label-skill">FLEXIBLTY</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value" />
            </div>
          </div>
          <div className="about-section__skill">
            <p className="about-section__label-skill">PUBLIC SPEAKING</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value" />
            </div>
          </div>
          <div className="about-section__skill">
            <p className="about-section__label-skill">CREATIVITY</p>
            <div className="about-section__skill-progress">
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value skill-value" />
              <s className="about-section__value" />
            </div>
          </div>
        </div>
        <img className="about-section__logo" src={String(logo)} alt="logo" />
      </div>
    </section>
  );
};

export default AboutSkillsSection;
