import classNames from "classnames";
import React from "react";

export type RadioOption = {
  label: string;
  value: string;
};

interface Props {
  options: RadioOption[];
  selected: string;
  name: string;
  orientation: "vertical" | "horizontal";
  onSelect: React.ChangeEventHandler<HTMLInputElement>;
}
const Radio = ({ name, options, orientation, selected, onSelect }: Props) => {
  return (
    <div
      className={classNames("radio", {
        "radio--horizontal": orientation === "horizontal",
      })}
    >
      {options.map((option) => (
        <div className="radio-option" key={option.value}>
          <input
            type="radio"
            id={option.label}
            name={name}
            value={option.value}
            checked={selected === option.value}
            onChange={onSelect}
            className="radio-control"
          />
          <label className="radio-label" htmlFor={option.label}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Radio;
