import React, { ReactNode } from "react";

import eduIcon from "./assets/edu-icon.svg";
import expIcon from "./assets/exp-icon.svg";
import aboutIcon from "./assets/person-icon.svg";

export type Item = {
  title: string;
  description: string;
  period?: string;
};

interface Props {
  type: "education" | "experince" | "about";
  items?: Item[];
  about?: ReactNode;
}
const AboutSection = ({ type, about, items }: Props) => {
  const getIcon = () => {
    if (type === "about") return String(aboutIcon);
    if (type === "education") return String(eduIcon);
    return String(expIcon);
  };

  const getTitle = () => {
    if (type === "about") return "ABOUT ME";
    if (type === "education") return "EDUCATION";
    return "EXPERINCE";
  };
  return (
    <section className="about-section">
      <header className="about-section__header">
        <img className="about-section__icon" src={getIcon()} alt="icon" />
        <h2 className="about-section__title">{getTitle()}</h2>
      </header>
      <div className="about-section__content">
        {type === "about" ? (
          <span>{about}</span>
        ) : (
          items?.map((item) => (
            <div className="about-section__item" key={item.title}>
              <span className="about-section__item-header">
                <span className="about-section__item-title">{item.title}</span>
                <span className="about-section__item-period">
                  {item.period}
                </span>
              </span>
              <span className="about-section__item-description">
                {item.description}
              </span>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default AboutSection;
