import React, { FormEvent, useContext, useEffect, useState } from "react";
import { MessageContext, UserContext, apiurl } from "../../App";
import UserCommentPost from "../user-comment-post/UserCommentPost";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Reply, { ReplyType } from "./Reply";

interface Props {
  isAuth: boolean;
  commentId: string;
  endPoint: string;
}
const Replies = ({ isAuth, commentId, endPoint }: Props) => {
  const { user } = useContext(UserContext);
  const { onError, onSuccess } = useContext(MessageContext);

  const [replies, setReplies] = useState<ReplyType[]>([]);
  const [reply, setReply] = useState<string>("");

  const navigate = useNavigate();

  const onPostReply = (e: FormEvent) => {
    e.preventDefault();
    if (isAuth) {
      if (!reply.trim()) {
        onError("It's an empty Reply!");
      } else {
        axios
          .post(apiurl + "/replies/", {
            date: new Date().toLocaleDateString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            user_id: user.id,
            comment_id: commentId,
            reply,
          })
          .then((res) => {
            const { data } = res;
            if (res.status === 404) {
              onError(data.error);
            } else {
              setReplies([data.reply, ...replies]);
              onSuccess(data.message);
              setReply("");
            }
          })
          .catch(() => onError("Failed to Post your Reply!"));
      }
    } else {
      navigate("/sign");
    }
  };

  useEffect(() => {
    fetch(apiurl + "/replies/" + commentId)
      .then((res) => res.json())
      .then((data) => {
        setReplies(data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="replies">
      <UserCommentPost
        placeholder="Reply to this comment..."
        avatar={user.avatar}
        comment={reply}
        onChange={(e) => setReply(e.target.value)}
        onPost={onPostReply}
      />
      <div className="replies__items">
        {Boolean(replies.length) ? (
          replies?.map((reply) => <Reply key={reply.id} reply={reply} />)
        ) : (
          <p className="replies__message">No replies yet</p>
        )}
      </div>
    </div>
  );
};

export default Replies;
