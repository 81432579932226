import React, { useContext, useState } from "react";
import { MessageContext, apiurl } from "../../App";
import axios from "axios";
import Button from "../button/Button";

const AddProjectForm = ({
  ProjectData,
  setProjectData,
  handleMainProjectImage,
}) => {
  const [images, setImages] = useState([]);

  const { onError, onSuccess } = useContext(MessageContext);

  const handleProjectImages = (event) => {
    const files = event.target.files;
    // Convert the FileList to an array of File objects
    const fileList = Array.from(files);

    // Process each file and read its data as a base64 string
    fileList.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // reader.result contains the base64 string representation of the image
        setImages((prevImages) => [...prevImages, reader.result]);
      };
      reader.onerror = () => {
        console.error("Failed to read the file");
      };
      reader.readAsDataURL(file);
    });
  };

  const AddProject = (e) => {
    e.preventDefault();
    axios
      .post(apiurl + "/projects", {
        title: ProjectData.title,
        main_image: ProjectData.main_image,
        images: images,
      })
      .then((res) => {
        const { data } = res;
        if (data.error) {
          onError(data.error);
        } else {
          onSuccess("Posted new Project!");
        }
      })
      .catch((err) => {
        onError(String(err));
      });
  };
  return (
    <div className="admin-panel__project-form">
      <form onSubmit={AddProject}>
        <h4 className="text-center p-3">Add Project</h4>
        <div className="mb-3 mt-3">
          <label className="form-label" htmlFor="title" type="text">
            Title
          </label>
          <input
            className="form-control"
            id="title"
            value={ProjectData.title}
            onChange={(e) =>
              setProjectData({ ...ProjectData, title: e.target.value })
            }
            placeholder="title here..."
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="img1">
            Main Image for the Project
          </label>
          <input
            type="file"
            className="form-control"
            id="img1"
            onChange={handleMainProjectImage}
            placeholder="image size is 9:6"
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="img1">
            Images
          </label>
          <input
            type="file"
            multiple={true}
            className="form-control"
            id="img1"
            onChange={handleProjectImages}
            placeholder="image size is 9:6"
          />
        </div>
        <Button pink text="Add new Project" type="submit" />
      </form>
    </div>
  );
};
export default AddProjectForm;
