import React from "react";
import { Link } from "react-router-dom";

import insta from "../../assets/svgs/insta-icon.svg";
import twitter from "../../assets/svgs/X.svg";
import tiktok from "../../assets/svgs/tiktok-icon.svg";
import whatsapp from "../../assets/svgs/whatsapp-icon.svg";
import youtube from "../../assets/svgs/youtube-icon.svg";
import FooterSplash from "../../assets/splashes/FooterSplash";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__social-links">
        <a
          className="footer__social-link"
          href="https://www.instagram.com/maha_binsahl/"
        >
          <img
            className="footer__social-icon"
            src={String(insta)}
            alt="instagram logo"
          />
        </a>
        <a className="footer__social-link" href="https://wa.link/zij507">
          <img
            className="footer__social-icon"
            src={String(whatsapp)}
            alt="whatsapp logo"
          />
        </a>
        <a
          className="footer__social-link"
          href="https://www.tiktok.com/@maha_binsahl?is_from_webapp=1&sender_device=pc"
        >
          <img
            className="footer__social-icon"
            src={String(tiktok)}
            alt="tiktok logo"
          />
        </a>
        <a
          className="footer__social-link"
          href="https://twitter.com/Maha_BinSahl"
        >
          <img
            className="footer__social-icon"
            src={String(twitter)}
            alt="twitter logo"
          />
        </a>
        <a
          className="footer__social-link"
          href="https://www.youtube.com/@MahaBinSahl"
        >
          <img
            className="footer__social-icon"
            src={String(youtube)}
            alt="youtube logo"
          />
        </a>
      </div>

      <div className="footer__web-links">
        <Link className="footer__web-link" to="/">
          HOME
        </Link>
        <Link className="footer__web-link" to="/shop">
          SHOP
        </Link>
        <Link className="footer__web-link" to="/blogs">
          BLOG
        </Link>
        <Link className="footer__web-link" to="/gallery">
          GALLERY
        </Link>
        <Link className="footer__web-link" to="/breathe">
          BREATHE
        </Link>
      </div>
      <h3 className="footer__copyright">
        {new Date().getFullYear()} © mahabnsahl.com
      </h3>
    </footer>
  );
};

export default Footer;
