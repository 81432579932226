export default function HeaderHomeLogo(){
    return(
        <svg viewBox="0 0 230 159" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_216_132)">
        <path d="M5.18994 14.75C5.18994 7.7 10.78 0 23.78 0H42.6801L52.92 40.36L64.16 0H81.8101V2.63C80.4001 2.63 77.6899 4 77.6899 5.89V43.83C77.6899 51.23 93.58 56.98 99.88 56.98C102.82 56.98 105.18 55.98 105.18 52.78C109.77 52.78 112.06 55.62 112.06 58.88C112.06 64.63 106.24 68.07 101.06 68.07C85.7601 68.07 74.3401 49.75 60.0601 49.75V47.09C61.4701 47.09 64.1801 45.72 64.1801 43.83V13.51L53.9399 49.78H41.58L30.22 6.22V43.86C30.22 45.75 33.81 47.12 35.22 47.12V49.78H21.6899V47.12C23.0999 47.12 26.6899 45.75 26.6899 43.86V5.92C26.6899 3.97 24.86 3.02 22.51 3.02C16.04 3.02 12.6801 8.18 12.6801 11.67C12.6801 13.33 13.5 14.67 15.21 14.67C15.8663 14.6909 16.5148 14.523 17.0785 14.1862C17.6422 13.8494 18.0974 13.3579 18.39 12.77C19.9756 12.929 21.4415 13.6848 22.4906 14.8843C23.5397 16.0838 24.0937 17.6373 24.04 19.23C24.04 23.56 20.04 25.93 15.74 25.93C9.06999 25.9 5.18994 20.56 5.18994 14.75Z" fill="#E22758"/>
        <path d="M99.9399 27.0199C101.083 27.0251 102.224 27.1255 103.35 27.3199V25.4199C103.35 22.1599 103.12 18.1299 98.8199 18.1299C94.1699 18.1299 93.7 22.3999 93.7 26.8499C92.5522 27.5004 91.2488 27.8254 89.9301 27.7899C87.6401 27.7899 85.4 26.8499 85.4 24.4099C85.4 18.4899 92.94 15.4099 100.59 15.4099C108.41 15.4099 116.3 18.6099 116.3 25.2499V43.8599C116.3 45.7499 119.01 47.1199 120.42 47.1199V49.7799H107.42C106.658 49.793 105.905 49.6173 105.228 49.2682C104.551 48.9192 103.971 48.4079 103.54 47.7799C101.32 49.3651 98.6771 50.2529 95.95 50.3299C88.12 50.3299 83.88 44.9899 83.88 39.4799C83.93 33.4199 88.9999 27.0199 99.9399 27.0199ZM96.88 39.7099C96.88 43.1399 98.82 44.8599 101.12 44.8599C101.893 44.8493 102.655 44.6684 103.35 44.3299V30.9899C101.486 31.5691 99.8545 32.7261 98.6914 34.2936C97.5283 35.8612 96.8939 37.758 96.88 39.7099Z" fill="#E22758"/>
        <path d="M122.54 49.7799V47.1199C123.95 47.1199 126.66 45.7499 126.66 43.8599V8.46992C126.687 8.05499 126.625 7.63909 126.477 7.25043C126.329 6.86176 126.099 6.50942 125.803 6.2173C125.508 5.92518 125.152 5.70011 124.762 5.55736C124.371 5.4146 123.955 5.3575 123.54 5.38992V2.71992C124.01 2.71992 135.07 1.41992 136.54 1.41992C137.351 1.4469 138.122 1.77917 138.699 2.35025C139.275 2.92133 139.615 3.6891 139.65 4.49992V21.4999C140.928 19.6986 142.6 18.2127 144.539 17.1556C146.479 16.0985 148.634 15.498 150.84 15.3999C157.07 15.3999 162.9 19.1899 162.9 25.8899V43.8899C162.9 45.7799 165.61 47.1499 167.02 47.1499V49.8099H145.83V47.1499C147.25 47.1499 149.95 45.7799 149.95 43.8899V26.0699C149.95 22.6399 148.01 20.9199 145.72 20.9199C143.43 20.9199 141.01 22.5199 139.65 25.5999V43.8599C139.65 45.7499 142.36 47.1199 143.77 47.1199V49.7799H122.54Z" fill="#E22758"/>
        <path d="M169.09 39.5299C169.09 33.4199 174.15 27.0199 185.09 27.0199C186.233 27.0256 187.374 27.1259 188.5 27.3199V25.4199C188.5 22.1599 188.27 18.1299 183.97 18.1299C179.32 18.1299 178.85 22.3999 178.85 26.8499C177.702 27.499 176.399 27.8239 175.08 27.7899C172.79 27.7899 170.55 26.8499 170.55 24.4099C170.55 18.4899 178.08 15.4099 185.73 15.4099C193.56 15.4099 201.45 18.6099 201.45 25.2499V43.8599C201.45 47.8599 203.04 49.6599 204.69 49.6599C206.34 49.6599 208.28 47.5299 208.28 43.6599C208.364 42.7904 208.146 41.9186 207.664 41.1904C207.181 40.4622 206.463 39.9218 205.63 39.6599C205.748 38.0906 206.469 36.6278 207.641 35.5783C208.814 34.5287 210.347 33.9738 211.92 34.0299C212.783 33.9905 213.644 34.1272 214.452 34.4317C215.261 34.7361 215.998 35.202 216.62 35.8008C217.243 36.3996 217.736 37.1189 218.072 37.9147C218.407 38.7106 218.576 39.5664 218.57 40.4299C218.57 48.7799 210.1 52.6399 202.15 52.6399C196.68 52.6399 191.44 50.7999 189.38 47.2999C187.018 49.143 184.134 50.1929 181.14 50.2999C173.33 50.3799 169.09 45.0399 169.09 39.5299ZM186.27 44.8599C187.047 44.8505 187.812 44.6695 188.51 44.3299V30.9899C186.646 31.5691 185.015 32.7261 183.851 34.2936C182.688 35.8612 182.054 37.758 182.04 39.7099C182 43.1399 184 44.8599 186.27 44.8599Z" fill="#E22758"/>
        <path d="M135.17 60.1201H149.93C157.63 60.1201 163.5 60.3701 163.5 67.4001C163.5 72.5401 159.9 74.8301 155.68 75.4001V75.5201C163.06 76.4101 165.13 81.0301 165.13 83.9601C165.13 89.7101 162.9 92.2001 158.35 93.2401C162.35 94.0901 172.71 98.7901 176.98 98.7901C178.98 98.7901 180.57 98.1001 180.57 95.9301C183.68 95.9301 185.24 97.8601 185.24 100.07C185.24 103.97 181.24 106.3 177.78 106.3C167.41 106.3 159.67 93.8901 149.97 93.8901V92.0801C155.08 92.0801 155.56 88.9401 155.56 84.0801C155.56 77.0801 152.56 76.6101 146.3 76.6101V89.8301C146.3 91.1101 148.14 92.0401 149.09 92.0401V93.8501H134.33V92.0101C135.33 92.0101 137.12 91.0801 137.12 89.8001V64.1401C137.12 62.8201 135.89 62.1401 134.29 62.1401C132.636 62.0673 131.019 62.6381 129.777 63.7327C128.535 64.8272 127.765 66.3603 127.63 68.0101C127.63 69.1301 128.19 70.0101 129.34 70.0101C129.786 70.0256 130.227 69.9121 130.61 69.6833C130.993 69.4545 131.302 69.1201 131.5 68.7201C132.575 68.8285 133.568 69.3412 134.279 70.1544C134.99 70.9676 135.366 72.0205 135.33 73.1001C135.33 76.0401 132.62 77.6401 129.7 77.6401C125.19 77.6401 122.56 74.0301 122.56 70.0901C122.56 65.3501 126.35 60.1201 135.17 60.1201ZM153.92 67.5601C153.92 64.2201 153.72 61.9301 149.92 61.9301C148.24 61.9301 146.29 62.3301 146.29 64.1401V74.6301C151.17 74.6301 153.92 72.9001 153.92 67.5601Z" fill="#FF5052"/>
        <path d="M167.37 93.8799V92.0699C168.37 92.0699 170.16 91.1399 170.16 89.8599V75.7499C170.178 75.4634 170.134 75.1762 170.032 74.9081C169.929 74.6399 169.77 74.397 169.565 74.1958C169.36 73.9947 169.114 73.84 168.844 73.7424C168.574 73.6447 168.286 73.6064 168 73.6299V71.8199C168.32 71.8199 175.82 70.9299 176.78 70.9299C177.331 70.9472 177.855 71.1723 178.246 71.5601C178.637 71.9478 178.868 72.4694 178.89 73.0199V89.8199C178.89 91.0999 180.73 92.0299 181.69 92.0299V93.8399L167.37 93.8799ZM174.55 61.0899C175.425 61.084 176.283 61.3382 177.014 61.8202C177.745 62.3023 178.316 62.9905 178.655 63.7976C178.994 64.6047 179.086 65.4943 178.919 66.3537C178.752 67.2131 178.333 68.0034 177.716 68.6246C177.099 69.2457 176.312 69.6697 175.453 69.8427C174.595 70.0157 173.705 69.9299 172.896 69.5963C172.086 69.2626 171.394 68.6962 170.907 67.9687C170.42 67.2412 170.16 66.3854 170.16 65.5099C170.156 64.9309 170.267 64.3568 170.485 63.8207C170.704 63.2847 171.027 62.7972 171.435 62.3863C171.843 61.9755 172.328 61.6494 172.863 61.427C173.398 61.2045 173.971 61.0899 174.55 61.0899Z" fill="#FF5052"/>
        <path d="M185.23 93.8801V92.0701C186.23 92.0701 188.03 91.1401 188.03 89.8601V75.8601C188.042 75.5804 187.995 75.3013 187.892 75.041C187.789 74.7806 187.632 74.5448 187.432 74.3487C187.233 74.1527 186.994 74.0007 186.732 73.9028C186.469 73.8048 186.189 73.763 185.91 73.7801V71.9701C186.23 71.9701 193.73 71.0801 194.69 71.0801C195.241 71.0973 195.765 71.3224 196.156 71.7102C196.547 72.0979 196.778 72.6195 196.8 73.1701V74.7001C197.664 73.4779 198.797 72.4695 200.11 71.752C201.424 71.0344 202.885 70.6268 204.38 70.5601C208.61 70.5601 212.56 73.1301 212.56 77.6701V89.8501C212.56 94.2701 212.12 105.03 218.79 105.03C219.205 105.04 219.617 104.965 220.002 104.812C220.387 104.658 220.737 104.428 221.031 104.136C221.326 103.843 221.558 103.495 221.714 103.111C221.87 102.726 221.947 102.315 221.94 101.9C221.956 101.454 221.844 101.013 221.617 100.628C221.39 100.244 221.058 99.932 220.66 99.7301C220.744 98.6665 221.235 97.6763 222.03 96.965C222.825 96.2537 223.864 95.8759 224.93 95.9101C225.515 95.8843 226.099 95.9777 226.646 96.1845C227.194 96.3913 227.694 96.7072 228.115 97.1131C228.537 97.5189 228.872 98.0063 229.1 98.5456C229.328 99.0848 229.443 99.6647 229.44 100.25C229.44 105.76 223.06 107.2 218.55 107.2C208.61 107.2 203.79 101.7 203.79 91.8601V77.8001C203.79 75.4701 202.47 74.3101 200.91 74.3101C199.994 74.3925 199.121 74.7345 198.393 75.2961C197.665 75.8576 197.112 76.6153 196.8 77.4801V89.8601C196.8 91.1401 198.64 92.0701 199.6 92.0701V93.8801H185.23Z" fill="#FF5052"/>
        <path d="M66.92 109.63C68.1567 109.637 69.3906 109.744 70.61 109.95V107.93C70.61 104.41 70.35 100.07 65.72 100.07C60.72 100.07 60.1899 104.67 60.1899 109.47C58.9528 110.167 57.5491 110.512 56.13 110.47C53.65 110.47 51.24 109.47 51.24 106.82C51.24 100.43 59.37 97.1001 67.62 97.1001C76.07 97.1001 84.62 100.56 84.62 107.72V127.8C84.62 129.8 87.5401 131.31 89.0601 131.31V134.19H75.0601C73.5401 134.19 71.57 133.43 70.87 132.08C68.4748 133.792 65.623 134.75 62.6801 134.83C54.2301 134.83 49.6801 129.08 49.6801 123.13C49.6501 116.56 55.11 109.63 66.92 109.63ZM63.62 123.31C63.62 127.02 65.7199 128.87 68.1899 128.87C69.028 128.855 69.8532 128.66 70.61 128.3V113.94C66.92 114.84 63.62 118.87 63.62 123.34V123.31Z" fill="#FC7E29"/>
        <path d="M91.3101 134.21V131.33C92.8301 131.33 95.75 129.86 95.75 127.82V89.63C95.7803 89.1819 95.7135 88.7325 95.5542 88.3125C95.3949 87.8926 95.1467 87.512 94.8268 87.1968C94.5068 86.8816 94.1225 86.6392 93.7002 86.4861C93.2779 86.3331 92.8276 86.273 92.38 86.31V83.43C92.89 83.43 104.83 82.02 106.38 82.02C107.258 82.047 108.094 82.4053 108.719 83.023C109.344 83.6407 109.713 84.472 109.75 85.35V103.7C111.124 101.754 112.925 100.148 115.015 99.0054C117.106 97.8631 119.43 97.2147 121.81 97.11C128.54 97.11 134.81 101.21 134.81 108.43V127.81C134.81 129.81 137.73 131.32 139.25 131.32V134.2H116.4V131.33C117.92 131.33 120.84 129.86 120.84 127.82V108.63C120.84 104.93 118.74 103.07 116.27 103.07C113.8 103.07 111.19 104.8 109.73 108.12V127.82C109.73 129.82 112.65 131.33 114.17 131.33V134.21H91.3101Z" fill="#FC7E29"/>
        <path d="M102.48 139.06C103.663 139.085 104.83 139.347 105.91 139.83C105.67 140.442 105.542 141.092 105.53 141.75C105.53 147.25 116.45 148.34 120.13 148.34C129.59 148.34 138.67 145.78 147.43 143.41C146.363 139.952 145.85 136.348 145.91 132.73V114.55C145.94 114.102 145.874 113.652 145.714 113.232C145.555 112.813 145.307 112.432 144.987 112.117C144.667 111.802 144.283 111.559 143.86 111.406C143.438 111.253 142.988 111.193 142.54 111.23V108.35C143.05 108.35 154.99 106.94 156.54 106.94C157.418 106.967 158.254 107.325 158.879 107.943C159.504 108.561 159.873 109.392 159.91 110.27V129.54C159.811 133.1 159.918 136.662 160.23 140.21C163.705 139.541 167.232 139.18 170.77 139.13C180.99 139.13 185.12 143.22 185.12 147.5C185.12 152.24 180.12 157.16 169.43 157.16C160.73 157.16 154.51 154.47 150.64 149.49C138.7 153.19 126.32 158.63 113.75 158.63C106.38 158.63 95.97 155.43 95.97 146.29C95.94 141.94 98.67 139.06 102.48 139.06ZM169.78 153.71C172.32 153.71 174.93 151.34 174.93 149.29C174.93 146.48 170.36 146.23 168.45 146.23C166.151 146.231 163.857 146.422 161.59 146.8C162.93 150.77 165.4 153.71 169.78 153.71Z" fill="#FC7E29"/>
        <path d="M23.84 69.4299C33.15 69.4299 45.67 71.0899 45.67 83.0599C45.67 86.8099 42.17 89.6199 38.67 89.6199C27.43 89.6199 32.3701 72.8199 22.0601 72.8199C17.9801 72.8199 14.54 76.8199 14.54 82.8199C14.54 87.5099 17.54 89.6699 17.54 93.9999C17.54 99.4799 12.88 103 14.96 104.52C15.2646 104.767 15.6483 104.895 16.04 104.88C18.76 104.88 22.48 99.3299 29.21 99.3299C38.66 99.3299 45.67 107.55 45.67 116.85C45.67 132.14 34.51 135.74 21.83 135.74C12.53 135.74 0 134.08 0 122.12C0 118.37 3.51 115.55 7 115.55C18.24 115.55 13.3 132.35 23.61 132.35C27.69 132.35 31.13 128.97 31.13 122.98C31.13 105.32 -0.0100098 111.98 -0.0100098 88.9799C-9.76585e-06 73.6799 11.17 69.4299 23.84 69.4299Z" fill="#FC7E29"/>
        <path d="M190.16 135.99C191.41 135.73 192.58 135.56 193.69 135.22C197.573 133.992 200.81 131.273 202.69 127.66C204.443 124.481 205.609 121.012 206.13 117.42C206.34 116.16 206.44 114.88 206.59 113.6C206.594 113.557 206.606 113.515 206.627 113.477C206.647 113.439 206.676 113.406 206.71 113.38C206.81 114.26 206.88 115.14 207 116.01C207.473 120.531 208.957 124.889 211.34 128.76C212.569 130.772 214.257 132.464 216.267 133.696C218.276 134.929 220.55 135.667 222.9 135.85L223.27 135.93C222.27 136.1 221.43 136.21 220.59 136.41C218.573 136.902 216.679 137.803 215.024 139.057C213.369 140.311 211.989 141.891 210.97 143.7C209.132 146.829 207.897 150.275 207.33 153.86C207.05 155.39 206.89 156.95 206.65 158.63C206.54 157.63 206.47 156.77 206.35 155.9C205.879 151.416 204.421 147.093 202.08 143.24C200.908 141.293 199.309 139.637 197.403 138.398C195.498 137.16 193.335 136.37 191.08 136.09C190.82 136.05 190.54 136.03 190.16 135.99Z" fill="white"/>
        <path d="M191.06 128.55C190.85 127.48 190.69 126.39 190.4 125.33C190.179 124.508 189.874 123.71 189.49 122.95C189.095 122.048 188.485 121.257 187.712 120.646C186.94 120.036 186.029 119.625 185.06 119.45L185.36 119.36C186.115 119.206 186.83 118.898 187.462 118.456C188.093 118.014 188.627 117.447 189.03 116.79C190.011 115.186 190.632 113.388 190.85 111.52C190.893 111.123 190.974 110.732 191.09 110.35C191.154 110.969 191.254 111.583 191.39 112.19C191.703 113.467 192.114 114.717 192.62 115.93C193.008 116.867 193.637 117.684 194.443 118.299C195.249 118.914 196.204 119.304 197.21 119.43C196.96 119.51 196.86 119.55 196.76 119.57C195.952 119.737 195.191 120.077 194.528 120.568C193.865 121.058 193.316 121.687 192.92 122.41C191.93 124.171 191.333 126.126 191.17 128.14C191.17 128.28 191.17 128.42 191.12 128.56L191.06 128.55Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_216_132">
        <rect width="229.44" height="158.63" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
        

    )
}