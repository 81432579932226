import React from "react";

export default function BottomWorkSplash() {
  return (
    <div className="bottom-work-splash">
      <svg
        viewBox="0 0 1420 287"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.68778 27.9598C28.5878 -4.81023 95.1478 -5.98023 140.308 10.0898C175.018 22.4198 187.048 28.6597 251.308 98.4097C287.508 137.7 325.458 171.59 382.078 159.63C428.768 149.77 466.078 113.48 514.568 108.88C561.168 104.46 604.478 132.15 640.508 162.02C693.918 206.3 721.078 201.36 777.928 161.51C934.618 51.6698 1183.8 27.7498 1336.93 157.64C1355.79 173.629 1372.83 191.648 1387.74 211.37C1396.52 222.99 1426.49 256.82 1418.12 272.11C1412.2 282.93 1389.6 278.11 1312.83 269.82C1278.28 266.09 1243.51 264.41 1209.03 260.05C916.258 223.05 617.808 318.86 326.028 274.97C288.168 269.28 250.578 261.24 212.388 258.62C162.278 255.18 110.598 260.94 62.8278 245.42C43.2278 239.09 23.1478 226.89 14.7678 206.51C8.86778 192.18 11.2278 178.89 12.2378 172.28C23.5278 97.8797 -14.8522 60.1798 6.68778 27.9598Z"
          fill="#FFDCC5"
        />
      </svg>
    </div>
  );
}
