import React from "react";

export default function LeftSplash() {
  return (
    <div className="left-splash"  data-aos='fade-right'>
      <svg
        viewBox="0 0 1031 934"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="left-splash" clipPath="url(#clip0_102_4)">
          <path
            id="Vector"
            d="M845.72 303.435C896.555 222.591 1020.49 227.387 1030.17 155.698C1041.03 75.2705 942.748 28.8381 879.494 11.5977C802.87 -9.27956 718.212 -2.16549 647.465 34.571C603.11 57.5998 549.694 94.2114 518.896 134.224C489.375 172.57 456.454 206.031 407.332 219.204C368.715 229.559 327.455 223.632 289.122 212.264C266.719 205.656 244.587 197.154 221.33 195.072C125.162 186.375 55.9831 271.057 26.0523 351.929C19.6 369.357 8.59623 385.764 3.58696 403.553C-2.71278 425.915 0.749303 452.491 2.96948 475.27C12.6827 575.214 54.3457 672.125 121.527 746.903C130.061 756.398 138.983 765.552 148.197 774.388C218.59 841.801 307.341 899.471 403.038 921.75C630.009 974.568 886.89 848.256 925.916 705.558C968.454 550.006 769.755 424.222 845.72 303.435Z"
            fill="#FC7E29"
          />
        </g>
        <defs>
          <clipPath id="clip0_102_4">
            <rect width="1031" height="934" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
