import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MessageContext } from "../../App";
import LoginCard from "./LoginCard";
import RegisterCard from "./RegisterCard";
import Loading from "../loading/Loading";

const logo = require("../../assets/images/logo.png");

const Sign = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);

  const { onError } = useContext(MessageContext);

  return (
    <div className="sign-page">
      <div className="sign-page__header">
        <Link to="/" className="sign-page__home-link">
          <img className="sign-page__logo" src={String(logo)} alt="logo" />
        </Link>
      </div>
      {loading && <Loading />}
      {isLogin ? (
        <LoginCard
          setLoading={setLoading}
          onError={onError}
          setIsLogin={setIsLogin}
        />
      ) : (
        <RegisterCard
          setLoading={setLoading}
          onError={onError}
          setIsLogin={setIsLogin}
        />
      )}
    </div>
  );
};

export default Sign;
