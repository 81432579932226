import classNames from "classnames";
import React from "react";

interface Props {
  show: boolean;
  content: string;
  type: "error" | "success";
}
const Message = ({ content, type, show }: Props) => {


  if (show)
    return (
      <div className="message">
        <i
          className={classNames("bx", {
            "bxs-check-circle": type === "success",
            "bxs-error-circle": type === "error",
          })}
        ></i>
        <p className="message__text">{content}</p>
      </div>
    );
};

export default Message;
