import React from "react";

export default function LandWhiteSplash() {
  return (
    <div className="land-white-splash">
      <svg
        viewBox="0 0 560 1385"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_102_6)">
          <path
            d="M535.99 1376.86C507.88 1302.12 450.62 1238.79 379.08 1203.3C342.48 1185.15 298.69 1170.62 281.7 1133.46C268.18 1103.88 279.28 1075.14 279.34 1044.1C279.39 1015.72 273.81 986.42 254.34 964.61C246.56 955.88 237.03 948.89 227.27 942.45C176.55 908.96 117.76 888.27 69.8899 850.82C22.0199 813.37 -14.2401 750.15 5.5099 692.71C19.2899 652.64 56.3499 625.71 92.6099 603.79C128.87 581.87 167.96 560.79 191.12 525.3C245.03 442.68 186.12 359.67 119.12 310.03C101.5 297.03 82.5499 285.15 68.5798 268.26C55.5798 252.48 47.5799 233.04 42.7899 213.13C37.6199 191.49 36.0999 168.7 40.9099 146.97C48.8399 111.19 73.33 80.9699 102.06 58.2099C166.45 7.27995 254.39 -10.3601 334.85 5.87994C394.85 17.9899 461.01 51.3699 507.12 92.4799C553.23 133.59 541.81 214.48 544.57 269.62C559.92 576.79 575.17 886.4 529.4 1190.52C519.7 1254.91 507.4 1321.6 525.4 1384.18"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_102_6">
            <rect width="559.86" height="1384.15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
