import React from "react";

export default function BlogIcon() {
  return (
    <div className="blog-icon">
      <svg viewBox="0 0 633 521" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="blog-icon" clipPath="url(#clip0_1_3)">
          <path
            id="blog-icon-stroke-pen"
            d="M632.67 77.09C631.27 82.73 630.67 88.85 628.27 93.88C624.35 102.18 620.27 110.88 614.55 117.49C565.997 173.49 517.213 229.223 468.2 284.69C438.2 318.69 401.73 336.2 359.3 337.03C345.21 337.31 336.84 327.44 337.2 311.41C338.29 262.18 354.35 220.41 384.84 185.65C432.5 131.33 480.13 76.9733 527.73 22.58C545.19 2.58 565.93 -4.81999 589.57 3.15001C613.46 11.2 627.47 30.43 631.91 58.53C632.086 59.0348 632.342 59.5076 632.67 59.93V77.09ZM378.83 288.75C399.83 285.35 422.38 272.15 439.15 253.09C488.057 197.543 536.867 141.877 585.58 86.09C594.58 75.83 594.86 61.85 586.58 52.49C578.3 43.13 566.01 43.76 557.12 53.9C508.453 109.407 459.787 164.943 411.12 220.51C400.181 232.951 391.75 247.389 386.29 263.03C383.44 271.1 381.47 279.52 378.83 288.75Z"
            fill="#E22758"
          />
          <path
            id="blog-icon-txt"
            d="M159.12 313.52C159.12 358.92 159.2 404.31 159.07 449.71C159.07 460.45 162.23 468.89 171.01 473.37C179.79 477.85 187.52 475.11 194.45 467.84C204.17 457.64 214.21 447.84 223.9 437.6C226.242 434.993 229.123 432.927 232.344 431.545C235.564 430.164 239.047 429.5 242.55 429.6C311.943 429.7 381.337 429.7 450.73 429.6C480.5 429.6 503.96 408.6 510.73 375.91C511.712 370.873 512.204 365.752 512.2 360.62C512.39 344.4 512.2 328.19 512.28 311.98C512.28 297.23 520.77 286.7 532.45 286.8C544.13 286.9 552.45 297.26 552.36 311.67C552.19 333.21 552.93 354.86 551.21 376.25C547.29 424.93 510.03 467.72 467.37 474.04C461.077 474.936 454.727 475.374 448.37 475.35C382.99 475.443 317.61 475.443 252.23 475.35C250.548 475.283 248.873 475.594 247.328 476.26C245.782 476.927 244.406 477.931 243.3 479.2C234.7 488.2 226.21 497.5 217.03 505.76C197.74 523.12 176.2 526.51 154.03 514.7C131.45 502.7 119.26 481.4 119.15 452.91C118.79 360.97 118.767 269.03 119.08 177.09C119.22 115.17 162.77 64.52 217.33 63.67C273.84 62.82 330.33 63.38 386.88 63.48C398.72 63.48 407.61 73.57 407.69 86.29C407.77 99.29 398.83 109.15 386.57 109.17C331.39 109.23 276.22 109.17 221.04 109.22C189.04 109.22 164.39 133.39 159.6 169.38C159.198 172.979 159.031 176.6 159.1 180.22C159.1 224.66 159.107 269.093 159.12 313.52Z"
            fill="#FF5052"
          />
          <path
            id="blog-icon-dot-2"
            d="M249.15 245.52C239.47 245.52 229.78 245.61 220.1 245.52C208.1 245.39 199.38 235.86 199.27 223.02C199.15 210.02 208.05 199.93 220.13 199.83C239.67 199.657 259.203 199.657 278.73 199.83C290.85 199.94 299.73 210.03 299.57 223.05C299.44 235.89 290.69 245.39 278.7 245.52C268.85 245.62 259 245.52 249.15 245.52Z"
            fill="#FC7E29"
          />
          <path
            id="blog-icon-dot-1"
            d="M249.41 337.09C239.56 337.09 229.71 337.2 219.86 337.09C208.18 336.93 199.51 327.49 199.27 314.88C199.03 302.08 207.67 291.61 219.38 291.44C239.38 291.16 259.413 291.16 279.48 291.44C291.2 291.61 299.83 302.08 299.56 314.9C299.31 327.5 290.64 336.9 278.96 337.08C269.11 337.2 259.26 337.09 249.41 337.09Z"
            fill="#FC7E29"
          />
          <path
            id="blog-icon-pen"
            d="M0.0299887 332.64C0.0299887 309.38 0.0299887 198.11 0.0299887 174.84C0.0299887 166.4 2.52999 163.95 11.03 163.94C20.17 163.94 29.31 163.89 38.45 163.94C44.94 163.99 47.87 166.94 47.87 173.43C47.87 219.63 48.07 353.84 47.76 400.04C47.64 418.3 41.52 434.87 30.65 449.59C26.23 455.59 20.31 455.48 16.03 449.38C5.91999 435 0.359985 418.99 0.119985 401.38C-0.180015 378.45 0.049993 355.51 0.049993 332.57L0.0299887 332.64Z"
            fill="#e22758"
          />
          <path
            id="blog-icon-ereaser"
            d="M39.91 146.75H8.38999C8.38999 137.65 7.66 128.52 8.6 119.57C9.44 111.62 17.42 106.45 25.33 107.11C29.178 107.439 32.7743 109.159 35.4466 111.947C38.1188 114.735 39.6842 118.401 39.85 122.26C40.11 130.23 39.91 138.2 39.91 146.75Z"
            fill="#e22758"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_3">
            <rect width="632.68" height="520.66" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
