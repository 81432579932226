import React from "react";

export default function UpperWorkSplash2() {
  return (
    <div className="upper-work-splash2"  data-aos='fade-down' data-aos-delay='300'>
      <svg
        viewBox="0 0 1309 572"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1308.24 0C1188.55 1.36855 1069.68 19.8696 955.24 54.9399C912.45 68.0599 869.74 83.78 833.24 110.03C775 151.86 737.42 216.47 697.24 276.45C660.58 331.13 619.5 384.29 565.8 421.53C512.1 458.77 443.72 478.53 381.05 461.19C352.92 453.41 325.93 438.4 296.79 439.19C212.79 441.33 172 566.03 88.12 571.38C56.59 573.38 26.91 557.23 0 540.38L13.27 0H1308.24Z"
          fill="#E22758"
        />
      </svg>
    </div>
  );
}
