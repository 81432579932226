import React, { useEffect, useState } from "react";

import BlogIcon from "../elements/BlogIcon";
import Button from "../button/Button";
import BlogTeaser from "./BlogTeaser";
import Loading from "../loading/Loading";

import { BlogType } from "../../models//Blog";
import { getStoryblokData } from "../../utils/getStoryblokData";

const Blogs = () => {
  const [blogs, setBlogs] = useState<{ slug: string; content: BlogType }[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getBlogs = async () => {
      const { data: blogData } = await getStoryblokData<{
        stories: { slug: string; content: BlogType }[];
      }>("cdn/stories/", "blog");
      setBlogs(blogData?.stories);
      setLoading(false);
    };
    getBlogs();
  }, []);

  return (
    <main className="blogs-page">
      <section className="blogs-page__land">
        <BlogIcon />
        <div className="blogs-page__land-titles">
          <h1 data-aos="zoom-in-up">Monster</h1>
          <h1 data-aos="zoom-in-up" data-aos-delay="300">
            Brave
          </h1>
          <h1 data-aos="zoom-in-up" data-aos-delay="600">
            Super Designer
          </h1>
          <Button
            text="Explore"
            type="button"
            orange
            onClick={() => (window.location.href = "#blogs_section")}
          />
        </div>
      </section>

      <h1 className="blogs-page__header">My Blog</h1>

      {loading ? (
        <Loading />
      ) : (
        <div id="blogs-page__blogs-section">
          {blogs?.length !== 0 ? (
            <div className="blogs-page__blogs-container">
              {blogs?.map((bl) => (
                <BlogTeaser key={bl.slug} blog={bl} />
              ))}
            </div>
          ) : (
            <h4 className="blogs-page__no-blogs-message">No blog yet :(</h4>
          )}
        </div>
      )}
    </main>
  );
};

export default Blogs;
