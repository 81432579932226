import {
    StoryblokClient,
    apiPlugin,
    getStoryblokApi as getStoryblokApiDefault,
    storyblokInit,
  } from "@storyblok/react";
  
  import { components } from "./StoryblokProvider";
  
  const storyblokApi: StoryblokClient | undefined = undefined;
  
  export const AppStoryblokInit = () => {
    storyblokInit({
      accessToken: process.env.REACT_APP_EXAMPLE,
      use: [apiPlugin],
      components,
      apiOptions: {
        region: "eu",
      },
    });
  
    return getStoryblokApiDefault();
  };
  
  export const getStoryblokApi = (): StoryblokClient => {
    if (storyblokApi !== undefined) return storyblokApi;
    return AppStoryblokInit();
  };
  