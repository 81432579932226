import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiurl } from "../../App";
import ProductItem from "../elements/ProductItem";

export default function Category() {
  const param = useParams();
  const [products, setProducts] = useState([]);
  const catgory = products.filter((item) => item.category === param.category);
  useEffect(() => {
    window.scrollTo(0,0)
    fetch(`${apiurl}/products`)
      .then((res) => res.json())
      .then((data) => setProducts(data))
      .catch((err) => alert("cats got error", err));
  }, []);

  return (
    <div className="container-fluid category-page">
      <div className="cat-header f-holder">
        <h1 className="text-center head-cat mt-5 mc_5">{param.category}</h1>
      </div>

      {catgory.length === 0 ? (
        <h1 className="text-center mt-5 mc_5">Coming soon</h1>
      ) : (
        <div className="row p-3">
          {catgory.map((p) => (
            <div className="col-sm-4 f-holder mt-3" key={p.id}>
              <ProductItem product={p} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
