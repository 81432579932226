import React from "react";
import { getAvatar } from "../../utils/getAvatar";

export type ReplyType = {
  id: string;
  reply: string;
  avatar: string;
  date: string;
  username: string;
};
interface Props {
  reply: ReplyType;
}
const Reply = ({ reply }: Props) => {
  return (
    <div className="reply">
      <span className="reply__date">{new Date(reply.date).toDateString()}</span>
      <img className="reply__avatar" alt="avatar" src={String(getAvatar(reply.avatar))} />
      <div className="reply__body">
        <h2 className="reply__username">{reply.username}</h2>
        <p className="reply__content">{reply.reply}</p>
      </div>
    </div>
  );
};

export default Reply;
