import React from "react";
import "./about.css";
import "./about-sm.css";
import logo from "../../../assets/svgs/logo.svg";
import mainlogo from "../../../assets/svgs/text-logo.svg";
import landPersona from "./assets/land-persona.svg";
import leftLabel from "./assets/left-label.svg";
import rightLabel from "./assets/right-label.svg";
import social from "./assets/social-links.svg";
import AboutSection, { Item } from "./AboutSection";
import AboutSkillsSection from "./AboutSkillsSection";
import AboutGeneral from "./AboutGeneral";

const persona = require("./assets/my-char.png");
const expData: Item[] = [
  {
    title: "RING SHOP",
    description:
      "A store that sells phones and accessories i rebrand the institution and worked with them 3-4 months in ksa",
    period: "JAN2022 - APR2022",
  },
  {
    title: "Athar association",
    description:
      "Voluntary center offering courses and lessons I’ve designed thir ads and social media posts - in Kuwait",
    period: "JUN2022 - MAR2023",
  },
  {
    title: "FREELANCE",
    description:
      "’ve worked during the years on more than 68 Projects Plus I’ve Worked with more than 100 customer proudly.",
    period: "JAN2022 - PRESENT",
  },
];

const eduData: Item[] = [
  {
    title: "Saihat High School | Saudi Arabia",
    description: "Graduated as the first and top on the class",
    period: "JUN2013 - DEC2015",
  },
  {
    title: "Graphic Design Course",
    description:
      "California Institute of the Arts certificationCalArts has earned an international reputation as the leading college of the visual and performing arts in the United States",
    period: "2020 - 2021",
  },
  {
    title: "packaging courseonline course",
    description: "on GD platform - pro level",
    period: "FEB-2023",
  },
];

const About = () => {
  const aboutBio = (
    <article className="about__aboutme">
      My Name is Maha Bin Sahl, I’m 25 years old, merried and a mom, and i’m a
      Graphic Designer specialized in LOGOS and brand identity, I Work as a
      Freelancer, bulit myself and my career from scratch
      <br />
      Always trying new experiments, always trying to make a difference and now
      i’m in this exciting journy building my comunity and my website, selling
      services and products and more,,
      <br />
      Describing Myself in Three Words :{" "}
      <span className="mc_1">superwoman</span>,{" "}
      <span className="mc_2">brave</span> and a{" "}
      <span className="mc_3">monster</span>
    </article>
  );
  return (
    <div className="about-page">
      <div className="about-land">
        <div className="about__logo-wrapper f-holder">
          <img className="about__logo" alt="logo" src={String(logo)} />
        </div>
        <div className="about__brand-wrapper">
          <h1 className="about__branding" data-aos="fade-up">
            BRANDING
          </h1>
          <img
            className="about__brand-persona"
            src={String(landPersona)}
            alt="persona"
            data-aos="fade-down"
          />
        </div>
        <div className="about__labels">
          <img
            className="about__left-label"
            src={String(leftLabel)}
            alt="left label"
            data-aos="fade-right"
          />
          <img
            className="about__right-label"
            src={String(rightLabel)}
            alt="right label"
            data-aos="fade-left"
          />
        </div>
      </div>
      <section className="about__land-bar">
        <img
          className="about__land-logo-bar"
          src={String(mainlogo)}
          alt="logo"
        />
        <h1 className="about__land-bar-designer">DESIGNER</h1>
        <img className="about__land-social" src={String(social)} alt="social" />
      </section>

      <section className="about-page__cv">
        <div className="about-page__cv-section">
          <AboutGeneral />
        </div>
        <div className="about-page__cv-section">
          <AboutSection type="experince" items={expData} />
          <AboutSkillsSection />
        </div>
        <div className="about-page__cv-section">
          <header className="about-page__cv-header">
            <img
              className="about-page__logo-header"
              src={String(logo)}
              alt="logo"
            />
            <img
              className="about-page__logo-header"
              src={String(persona)}
              alt="logo"
            />
          </header>
          <AboutSection about={aboutBio} type="about" />
          <AboutSection type="education" items={eduData} />
        </div>
      </section>
    </div>
  );
};

export default About;
