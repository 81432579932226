import male1 from "../assets/svgs/male-avatar1.svg";
import male2 from "../assets/svgs/male-avatar2.svg";
import female1 from "../assets/svgs/female-avatar1.svg";
import female2 from "../assets/svgs/female-avatar2.svg";
import guest from "../assets/svgs/guest-avatar.svg";
const admin = require("../assets/images/roundedChar.png");

export const getAvatar = (avatar) => {
  if (avatar === "admin") return admin;
  if (avatar === "male-avatar1") return male1;
  else if (avatar === "male-avatar2") return male2;
  else if (avatar === "female-avatar1") return female1;
  else if (avatar === "female-avatar2") return female2;
  else return guest;
};
