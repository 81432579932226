import React from "react";

export default function RightSplash() {
  return (
    <div className="right-splash"  data-aos='fade-left'>
      <svg
        viewBox="0 0 814 733"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="right-splash" clipPath="url(#clip0_102_2)">
          <path
            id="Vector"
            d="M151.352 74.656C152.23 74.1956 153.569 73.4987 155.206 72.6773C227.566 36.5886 298.844 60.4694 344.449 66.9031C474.958 85.3084 513.703 -22.317 614.618 4.23938C685.92 22.9993 724.279 91.9039 751.726 141.202C819.019 262.069 853.282 467.799 741.286 609.585C655.075 718.734 484.812 761.935 434.283 712.954C399.958 679.672 437.558 619.957 401.29 585.685C348.962 536.188 244.175 639.563 144.691 603.773C36.1507 564.723 -39.2849 370.54 21.8829 220.666C55.6042 138.178 120.868 92.9679 151.352 74.656Z"
            fill="#E22758"
          />
        </g>
        <defs>
          <clipPath id="clip0_102_2">
            <rect width="814" height="733" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
