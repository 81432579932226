import React, { useState } from "react";
import { CommentType } from "../../models/Comment";
import Replies from "../replies/Replies";
import classNames from "classnames";
import { getAvatar } from "../../utils/getAvatar";

interface Props {
  comment: CommentType;
  isAuth: boolean;
  replyEndPoint: string;
}
const Comment = ({ comment, isAuth, replyEndPoint }: Props) => {
  const [showReplies, setShowReplies] = useState(false);

  return (
    <div className="comment">
      <span className="comment__date">{new Date(comment.date).toDateString()}</span>
      <img
        className="comment__avatar"
        alt="avatar"
        src={String(getAvatar(comment.avatar))}
      />
      <div className="comment__body">
        <h2 className="comment__username">{comment.username}</h2>
        <p className="comment__content">{comment.comment}</p>
        <button
          className={classNames("comment__replies-button", {
            "comment__replies-button--open": showReplies,
          })}
          onClick={() => setShowReplies(!showReplies)}
        >
          <i className="bx bxs-message-rounded-dots"></i> {comment?.replies}
        </button>
      </div>
      {showReplies && (
        <Replies
          endPoint={replyEndPoint}
          isAuth={isAuth}
          commentId={comment.id}
          
        />
      )}
    </div>
  );
};

export default Comment;
